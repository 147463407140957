import React, { useEffect, useState } from "react";
// import LazyLoad from "react-lazy-load";
import { useParams } from "react-router-dom";

function Blogs() {
  const params = useParams();
  // console.log(params.postId);

  const [singlePost, setSinglePost] = useState([]);

  useEffect(() => {
    async function singlePostDetailPage() {
      // try {
      let singlePostResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/blogPostDetail?postId=${params.postId}`
      );
      let singlePostData = await singlePostResponse.json();
      setSinglePost(singlePostData.data);
      // } catch (error) {
      //   console.log("Error: ", error);
      // }
    }
    singlePostDetailPage();
  }, [params.postId]);

  return (
    <>
      <div className="container blog-post-view">
        {singlePost.map((item, i) => (
          <div className="blog-post-row" key={i}>
            <div className="blog-img">
              {/* <LazyLoad className="postImg blog-img"> */}
              <img
                src={item.img ? item.img : null}
                className="img-fluid"
                alt=""
                loading="eager"
              />
              {/* </LazyLoad> */}
            </div>
            <div className="blog-content">
              <div className="heading-date">
                <h1>{item.heading}</h1>
                <h5>
                  Blogbeitrag :{" "}
                  <span>
                    {item.time ? item.time.split("T")[0] : null}
                  </span>
                </h5>
              </div>
              <div
                className="editor-content"
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Blogs;
