import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function ContactUs() {
  let [bannerGroup, setBannerGroup] = useState([]);
  let [bannerImg, setBannerImg] = useState();
  let [bannerTitle, setBannerTitle] = useState();
  let [contactDetail, setContactDetail] = useState([]);
  let [contactDetailImage, setContactDetailImage] = useState();
  let [heading, setHeading] = useState();
  let [subContent, setSubContent] = useState();
  let [contactNumber, setContactNumber] = useState();
  let [emailAddress, setEmailAddress] = useState();
  let [address, setAddress] = useState();
  let [facebookUrl, setFacebookUrl] = useState();
  let [twitterUrl, setTwitterUrl] = useState();
  let [instagramUrl, setinstagramUrl] = useState();
  let [contactFormDetail, setContactFormDetail] = useState();

  useEffect(() => {
    const fetchdata = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/contactUsPage`
      );
      let contactPagedata = await response.json();
      setBannerGroup(contactPagedata.banner);
      setContactDetail(contactPagedata.contact_detail);
    };
    setTimeout(() => {
      fetchdata();
    }, 500);
  }, []);

  useEffect(() => {
    if (bannerGroup.banner_image) {
      setBannerImg(bannerGroup.banner_image);
      setBannerTitle(bannerGroup.banner_title);
    }
  }, [bannerGroup]);

  useEffect(() => {
    if (contactDetail) {
      setContactDetailImage(contactDetail.contact_detail_image);
      setHeading(contactDetail.heading);
      setSubContent(contactDetail.sub_content);
      setContactNumber(contactDetail.contact_number);
      setEmailAddress(contactDetail.email_address);
      setAddress(contactDetail.address);
      setFacebookUrl(contactDetail.facebook);
      setTwitterUrl(contactDetail.twitter);
      setinstagramUrl(contactDetail.instagram);
      setContactFormDetail(contactDetail.contact_form_detail);
    }
  }, [contactDetail]);

  useEffect(() => {
    AOS.init({ startEvent: "load" });
  }, []);

  const iframeUrl = `${process.env.REACT_APP_HOST_URL}/contact-us-page-form/`;

  return (
    <>
      <section className="contact_banner">
        <img src={bannerImg} alt="" />
        <div className="contact_heading">
          <h1>{bannerTitle}</h1>
        </div>
      </section>

      <section className="contact_form_wrapper">
        <div className="container">
          <div className="row">
            <div className="contact_form_cover">
              <div className="contact_form_img">
                <img
                  src={contactDetailImage}
                  alt=""
                  loading="eager"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                  data-aos-delay="500"
                />
                <div className="contact_form_detail">
                  <div className="contact_form_detail_head">{heading}</div>
                  <div className="contact_form_detail_text">{subContent}</div>
                  <div className="contact_form_detail_links">
                    <ul>
                      <li>
                        <a href={`tel:+` + contactNumber}>
                          <i className="fa fa-phone"></i>
                          <span>+{contactNumber}</span>
                        </a>
                      </li>
                      <li>
                        <a href={`mailto:` + emailAddress}>
                          <i className="fa fa-envelope"></i>
                          <span>{emailAddress}</span>
                        </a>
                      </li>
                      <li>
                        <i className="fas fa-map-marker-alt"></i>
                        <span>{address}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="contact_form_detail_social">
                    <ul>
                      <li>
                        <a href={facebookUrl}>
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href={twitterUrl}>
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href={instagramUrl}>
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="contact_form_frame">
                {/* <div className="contact_form_frame_head">{contactFormDetail}</div> */}
                <div
                  className="contact_form_frame_head"
                  dangerouslySetInnerHTML={{ __html: contactFormDetail }}
                />

                <iframe
                  title="Newsletter Subscription"
                  src={iframeUrl}
                  width="100%"
                  height="auto"
                  frameBorder="0"
                  scrolling="no"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                  data-aos-delay="500"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
