import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GTMInitializer = ({ gtmId }) => {
  const location = useLocation();

  useEffect(() => {
    // Initialize GTM if not already done
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    // Push the initial pageview event
    window.dataLayer.push({
      event: "pageview",
      page: location.pathname + location.search,
      title: document.title,
    });

    // Load the GTM script
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [gtmId]);

  useEffect(() => {
    // Push a new pageview event to GTM every time the route changes
    window.dataLayer.push({
      event: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null;
};

export default GTMInitializer;
